.permissions-card {
  border: 2px solid transparent;
  width: 100%;

  &.-checked {
    border: 2px solid $primary-color;
  }

  >.select {
    margin-top: auto;
  }
}
