.uploaded-file {
  border: 1px solid $gray-500;
  display: flex;
  width: fit-content;
  
  >.fileWrapper {
    padding: 10px;
    margin-right: 20px;
  }

  >.delete {
    align-items: center;
    background-color: $danger-color;
    border: none;
    color: $white-color;
    display: flex;
    justify-content: center;

    &:hover {
      background-color: $danger-color-700;
    }
  }
}