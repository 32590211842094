@import './variables';

.button {
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  transition: .4s ease all;

  &.-primary {
    background-color: $primary-color;
    border: none;
    color: $white-color;
    padding: 16px 20px;

    &:hover {
      background-color: $primary-color-700;
    }

    &:focus {
      outline: 3px solid $black-color;
    }
  }

  &.-secondary {
    background-color: transparent;
    border: 2px solid $primary-color;
    color: $primary-color;
    padding: 16px 20px;

    &:hover {
      background-color: $primary-color;
      color: $white-color;
    }

    &:focus {
      outline: 3px solid $black-color;
    }
  }

  &.-text {
    background-color: transparent;
    border: none;
    color: $primary-color;
    padding: 16px 20px;

    &.-danger {
      color: $danger-color;
      background-color: transparent;
      border: none;

      &:hover {
        color: $white-color;
      }
    }

    &:hover {
      color: $primary-color-700;
    }

    &:focus {
      outline: 3px solid $black-color;
    }
  }

  &.-danger {
    background-color: $danger-color;
    border: none;
    color: $white-color;
    padding: 16px 20px;

    &:hover {
      background-color: $danger-color-700;
    }

    &:focus {
      outline: 3px solid $black-color;
    }
  }

  &.-icon {
    background-color: transparent;
    border: 0;
    color: $primary-color;
    padding: 0;

    &:hover {
      color: $primary-color-700;
    }

    &:focus {
      outline: 3px solid $black-color;
    }

    &.-danger {
      background-color: transparent;
      color: $danger-color;

      &:hover {
        color: $danger-color-700;
      }
  
      &:focus {
        outline: 3px solid $black-color;
      }
    }
  }
  &.-cancel {
    background-color: $danger-color;
    border: none;
    color: $white-color;
    padding: 16px 20px;
    margin-left: 550px;

    &:hover {
      background-color: $danger-color-700;
    }

    &:focus {
      outline: 3px solid $black-color;
    }
  }
}
