.modal {
  background-color: #050817CC;

  >.modal-dialog >.modal-content {
    -webkit-box-shadow: x 4px 10px 0 rgba(0, 0, 0, .24);
    border: 0;
    border-radius: 0;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .24);

    >.modal-header {
      border-bottom: 0;
      justify-content: center;

      >.h1-b >.error {
        color: $danger-color;
        font-size: 48px;
      }

      >.btn-close {
        align-items: center;
        background-color: transparent;
        border: 0;
        color: $danger-color;
        display: flex;
        justify-content: center;
        padding: 0;
        position: absolute;
        right: 10px;
        top: 10px;

        &:hover {
          background-color: $danger-color;
          color: $white-color;
        }
      }
    }

    >.modal-footer {
      border-top: 0;
    }
  }
}
