.navbar-lg {
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
  align-items: center;
  background-color: $secondary-color;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
  display: flex;
  height: 70px;
  padding: 10px;

  >.container >.username {
    align-items: center;
    color: $white-color;
    display: flex;
    font-size: 16px;
  }
}
