.react-datepicker-wrapper {


  width: 100%;

  >.react-datepicker__input-container {

    >input {
      border: 1px solid $gray-500;
      height: 48px;
      padding: 10px;
      width: 100%;

      &:focus-visible {
        border: 1px solid $primary-color;
        outline: none;
      }
    }
  }
}

.react-datepicker__tab-loop {

  >.react-datepicker-popper {

    >div .react-datepicker.react-datepicker--time-only {

      >.react-datepicker__time-container >.react-datepicker__time {
        border-radius: 0;
      }

      >.react-datepicker__time-container >.react-datepicker__time >.react-datepicker__time-box >.react-datepicker__time-list >.react-datepicker__time-list-item.react-datepicker__time-list-item--selected  {
        background-color: $primary-color;
      }

      >.react-datepicker__time-container >.react-datepicker__header {
        background-color: $primary-color-800;
        border-radius: 0;

        >.react-datepicker-time__header {
          color: $white-color;
        }
      }
    }
  }
}

.date-picker-wrapper {

  >.react-datepicker__tab-loop >.react-datepicker-popper >div >.react-datepicker {

    // TRIANGLE
    >.react-datepicker__triangle {
      display: none;
    }

    >.react-datepicker__navigation >.react-datepicker__navigation-icon &::before {
      border-color: $white-color;
    }
    
    // HEADER
    >.react-datepicker__month-container >.react-datepicker__header {
      background-color: $primary-color-800;
      border-radius: 0;
      
      // MONTH NAME
      >.react-datepicker__current-month {
        color: $white-color;
      }
      
      // DAY NAME
      >.react-datepicker__day-names >.react-datepicker__day-name {
        color: $white-color;
      }
    }

    >.react-datepicker__month-container >.react-datepicker__month >.react-datepicker__week >.react-datepicker__day--selected {
      background-color: $primary-color;
    }
  }

  >.material-icons-outlined {
    position: absolute;
    pointer-events: none;
    right: 24px;
    top: 42px;
  }

  >.material-icons {
    position: absolute;
    pointer-events: none;
    right: 24px;
    top: 12px;
  }
  

}
