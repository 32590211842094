.page-item{
  background-color: transparent;
  border: none;
  color: $black-color;
  font-weight: bold;
  
  &.-active {
    padding: 10px 5px 10px 5px;
    border: 2px solid $gray-500;
  }
}

  button.page-link {
    cursor: pointer;
    background-color: transparent !important;
    border: 0 !important; 
    border-radius: 0;
    color: $primary-color-700;
    font-size: 16px;
    align-items: flex-start;
  }

  button.page-link.disabled {
    color: $gray-500;
    background-color: transparent !important;
    border: none !important;
    font-size: 16px;
    opacity: .4;
  }
