.paginator-component {
  align-items: center;
  display: flex;
  height: 100%;

  >.button {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0;
    width: 50px;
  }

  >.input-group {
    margin: 0 10px;
    width: 50px;
  }
}
