.campaign-bar {

  &:hover {
    text-decoration: none;

    >.content {
      -webkit-box-shadow: x 4px 10px 0 rgba(0, 0, 0, .24);
      border: 2px solid $primary-color;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .24);
    }
  }

  .content {
    background-color: $white-color;
    border: 2px solid transparent;
    padding: 20px;

    &:hover {
      >.row >.col-12 >.text.name {
        color: $primary-color;
        text-decoration: underline;
      }
    }

    >.row >.col-12 >.text {
      color: $secondary-color;
      margin-bottom: 0;
      word-break: break-all;

      &.-scope {
        color: $primary-color;
      }
    }
  }
}
