// Name colors
$royal-blue: #354de8;
$fire-bush: #ff9d00;
$lucky-point: #151f5c;
$haiti: #0b0f2e;
$gallery: #ededed;
$guardsman-red: #d50000;
$dove-gray: #707070;
$maroon: #800000;
$flirt: #A00094;

// Color declaration
$primary-color-100: #d8dbf7;
$primary-color: $royal-blue;
$primary-color-700: #202e8c;
$primary-color-800: $lucky-point;
$secondary-color: $haiti !important;

$tertiary-color: $flirt;

$gray-500: $dove-gray;
$gray-100: $gallery;
$white-color: #fff;
$black-color: #000;

$warning-color: $fire-bush;

$danger-color: $guardsman-red;
$danger-color-700: $maroon;


