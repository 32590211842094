.navbar {
  background-color: $secondary-color;

  >.container-fluid >.navbar-toggler {
    border: none;

    &:hover {
      opacity: .5;
    }

    >.navbar-toggler-icon {
      background-image: url('../../img/hamburguer.svg');
    }
  }

  >.container-fluid >.navbar-collapse >.navbar-nav >.nav-item >.nav-link {
    align-items: center;
    color: $white-color;
    display: flex;
    padding: 20px 0;

    &:focus {
      background-color: $white-color;
      color: $secondary-color;
    }
  }
}
