.h1-b {
  font-family: 'Roboto Mono', monospace;
  font-size: 30px;
  font-weight: 500;
}

.h2-r {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
}

.h2-b {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 700;
}

.h3-b {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.h4-b {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 700;
}

.h3-r {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
}

.body-r {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.body-b {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
}

.body-small-r {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.material-icons-outlined.md-18 {
  font-size: 18px;
}

.material-icons-outlined.md-24 {
  font-size: 24px;
}

.material-icons-outlined.md-36 {
  font-size: 36px;
}

.material-icons-outlined.md-48 {
  font-size: 48px;
}

body {
  background-color: $gray-100;
}

.inputname {
  font-size: 12px;
}

p {
  font-size: 16px;
}