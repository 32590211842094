.input-group {
  margin-top: 30px;
  position: relative;

  >.input-icon {
    color: $gray-500;
    position: absolute;
    right: 34px;
    top: 12px;
  }

  &.-error {
    input,
    select,
    textarea {
      border: 1px solid $danger-color;
    }

    >.errormesage {
      display: block !important;
    }
  }

  input,
  select,
  textarea {
    background-color: $white-color;
    border: 1px solid $gray-500;
    border-radius: 0;
    height: 48px;
    padding: 10px;
    transition: .4s ease all;
    width: 100%;

    &:focus ~ .input-icon {
      color: $primary-color;
    }
  }

  >.css-2b097c-container {
    cursor: pointer;
    width: 100%;

    >.css-yk16xz-control {
      background-color: $white-color;
      border: 1px solid $gray-500;
      border-radius: 0;
      padding: 5px;

      >.css-g1d714-ValueContainer .css-1rhbuit-multiValue {
        background-color: $primary-color-100;
      }
    }

    >.css-1pahdxg-control {
      background-color: $white-color;
      border: 1px solid $primary-color;
      border-radius: 0;
      box-shadow: none;
      padding: 5px;

      >.css-g1d714-ValueContainer .css-1rhbuit-multiValue {
        background-color: $primary-color-100;
      }
    }
  }

  input,
  select,
  textarea {
    &:focus {
      border: 1px solid $primary-color;
      outline: none;
    }

    &:focus ~ .inputname {
      color: $primary-color;
    }
  }

  >.inputname {
    font-size: 12px;
    margin-bottom: 0;
    padding: 5px;
    position: absolute;
    top: -28px;
    transition: .4s ease all;
  }

  >.errormesage {
    color: $danger-color;
    //display: none;
    font-size: 12px;
    margin-bottom: 0;
  }
}

// Upload file
input[type='file'] {
  display: none;
}

.file-upload {
  background-color: $gray-100;
  border: 2px dashed $primary-color;
  color: $primary-color;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: .4s ease all;

  &:hover {
    background-color: $primary-color;
    color: $white-color;
  }
}
