.template-info {
  background-color: $primary-color-100;
  display: flex;

  >.iconwrapper {
    align-items: center;
    background-color: $primary-color-800;
    display: flex;
    justify-content: center;
    width: 10%;
  }

  >.bodycontent {
    padding: 20px;
    width: 90%;
  }
}
