.error-meaning {
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
  display: flex;

  >.errorblock {
    @include media-breakpoint-down(md) {
      width: 46%;
    }
    @include media-breakpoint-down(sm) {
      width: 80%;
    }
    margin-right: 10px;
    width: 20%;

    &.-noerror {
      border-top: 10px solid $primary-color;
    }

    &.-registererror {
      border-top: 10px solid $tertiary-color;
    }

    &.-clouderror {
      border-top: 10px solid $gray-500;
    }

    &.-expirationerror {
      border-top: 10px solid $secondary-color;
    }

    &.-optimerror {
      border-top: 10px solid $danger-color-700;
    }

    &.-pending {
      border-top: 10px solid $yellow;
    }

    &.-status {
      @include media-breakpoint-down(sm) {
        width: 80%;
      }
      margin-right: 10px;
      width: 50%;

      &.-received {
        border-top: 10px solid $primary-color;
      }

      &.-interacted {
        border-top: 10px solid $secondary-color;
      }
    }
  }
}
