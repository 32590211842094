.progress {
  background-color: $primary-color-100;
  border-radius: 0;
  border: 2px solid $secondary-color;
  height: 20px;

  &.-xl {
    height: 50px;
  }

  >.progress-bar {
    background-color: $primary-color;
  }

  >.bg-noerror {
    background-color: $primary-color;
  }

  >.bg-registererror {
    background-color: $tertiary-color;
  }

  >.bg-clouderror {
    background-color: $gray-500;
  }

  >.bg-expirationerror {
    background-color: $secondary-color;
  }

  >.bg-optimerror {
    background-color: $danger-color-700;
  }

}
