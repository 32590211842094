table,
thead,
tbody,
tr,
th,
td {
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

table >thead >tr{
  @include media-breakpoint-down(sm) {
    display: none;
  }
  background-color: $secondary-color;
  

  th {
    color: $white-color;
    font-size: 18px;
    font-weight: 400;
    padding: 16px 10px;
  }
}

table >tbody >tr >td {
  @include media-breakpoint-down(sm) {
    border: 0 !important;
    padding: 5px !important;
  }
}

table >tbody >tr {
  @include media-breakpoint-down(sm) {
    padding: 10px 0;
  }
  border-bottom: 1px solid $gray-100;

  &:last-child {
    border-bottom: none;
  }

  >td {
    padding: 20px 10px;

    >button {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

table {
  background-color: $white-color;
  font-size: 14px;
  width: 100%;
}
