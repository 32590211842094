.constructor {
  @include media-breakpoint-down(md) {
    flex-direction: column;
    justify-content: center;
  }
  background-color: $white-color;
  display: flex;

  >.icon {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    align-items: center;
    background-image: url('../../img/banner_bg.svg');
    background-size: cover;
    border-bottom: 4px solid $primary-color;
    display: flex;
    justify-content: center;
    width: 40%;

    >.image {
      width: 160px;
    }
  }

  >.body {
    @include media-breakpoint-down(md) {
      border-bottom: none;
      width: 100%;
    }
    border-bottom: 4px solid $primary-color;
    padding: 20px;
    width: 60%;
  }
}
