.sidenav {
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: sticky;
  top: 0;
  width: 150px;
  z-index: 99;

  >.logocontainer {
    align-items: center;
    border-bottom: 1px solid $secondary-color;
    display: flex;
    height: 70px;
    justify-content: center;
  }

  >.navelement {

    &.-active {
      background-color: $primary-color;

      >.navlink {
        color: $white-color !important;

        >span {
          color: $white-color;
        }
      }
    }

    background-color: $white-color;
    border: none;
    border-bottom: 1px solid $primary-color-100;
    padding: 20px 10px;
    width: 100%;

    &:hover {
      background-color: $primary-color;

      >.navlink {
        color: $white-color !important;

        >span {
          color: $white-color;
        }
      }

      >.navlink {
        color: $white-color;
      }
    }

    >.navlink {
      align-items: center;
      color: $secondary-color;
      display: flex;
      text-align: left;
    }

    &.-logout {
      border-bottom: none;
      border-top: 1px solid $danger-color;
      margin-top: auto;

      &:hover {
        background-color: $danger-color;
        text-decoration: none;

        >.navlink {
          color: $white-color !important;
        }
      }

      >.navlink {
        background-color: transparent;
        border: none;
        color: $danger-color !important;
      }
    }
  }
}
