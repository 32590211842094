.mt-100 {
  margin-top: 100px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.c-primary {
  color: $primary-color;
}

.c-warning {
  color: $warning-color;
}

.c-danger {
  color: $danger-color;
}

.c-white {
  color: $white-color;
}

.c-primary-100 {
  color: $primary-color-100;
}

.min-h-page {
  min-height: calc(100vh - 70px);
}

.fs-18 {
  font-size: 18 !important;
}

.f-bold {
  font-weight: bold;
}

.mt-md-30 {
  @include media-breakpoint-down(md) {
    margin-top: 30px;
  }
}

.bg-secondary {
  background-color: $secondary-color;
}

.t-bold {
  font-weight: bold;
}

.px-xs {
  padding-left: 5px;
  padding-right: 5px;
}